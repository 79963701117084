// Core variables and mixins
@import "../../site/less/variables.less";
@import "../../site/less/mixins.less";
/* Theme Name:The Project - Responsive Website Template
Author:HtmlCoder
Author URI:http://www.htmlcoder.me
Author e-mail:htmlcoder.me@gmail.com
Version:1.1.0
Created:March 2015
License URI:http://support.wrapbootstrap.com/
File Description:Main CSS file of the template */
/* TABLE OF CONTENTS
1) Typography
2) Layout
3) Sections
4) Pages ( blog, about etc )
5) Components
6) Navigations
7) Blocks/Widgets
8) Main Slideshow
9) Owl carousel
* */
@import "../../site/less/1_typography.less";
@import "../../site/less/2_layout.less";
@import "../../site/less/3_sections.less";
@import "../../site/less/4_pages.less";
@import "../../site/less/5_components.less";
@import "../../site/less/6_navigations.less";
@import "../../site/less/7_blocks-widgets.less";
@import "../../site/less/8_main-slideshow.less";
@import "../../site/less/9_owl-carousel.less";
@import "../../site/less/covendis";
@import "../../site/less/magnific-popup";
@primary-color: #0DA9AA;
@brand-primary: @primary-color;
@import "home";


.m-t-5 {
  margin-top: 5px;
}

.m-b-50 {
  margin-bottom: 50px;
}

.career {
  .bottom-container {
    .fa-circle {
      font-size: 8px !important;
      margin: auto 10px;
    }
    a, p {
      display: inline-block;
      font-size: 12px !important;
    }
  }
}

.height-100 {
  min-height: 100px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.text-primary {
  color: @primary-color;
}

.paragraph {
  font-size: 15px;

  line-height: 30px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

.footer-label {
  color: @primary-color;
  margin: 10px 0 0 5px;
  font-size: 12px;
  font-weight: 400;
  font-family: 'Open Sans', sans-serif;
}

.site-slogan {
  position: absolute;
  bottom: 18px;
  left: 100px;
}

.img-career {
  display: block;
  margin: 40px auto
}

.header-link {
  color: white;

  &:hover, &:focus, &:active {
    color: @primary-color;
    text-decoration: none;
  }
}

.blogpost-content h2 {
  text-transform: none !important;
  font-size: 20px;
}

.p-0 {
  padding: 0;
}

.p-r-5 {
  padding-right: 5px !important;
}

.pedia-links {

  margin: 0 !important;
  a, span {

    border-radius: 0;
    padding: 7px 10px;
    border: 1px solid transparent;
    color: #999999;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 400;
    padding-right: 8px !important;
    padding-left: 8px !important;
    border-left: 1px solid #bab8b7 !important;
    border: 1px solid transparent;
    border-radius: 0 !important;
    color: @base-color !important;
    cursor: pointer;
    display: block;
    box-sizing: border-box;
  }

  &.disabled * {
    color: #bab8b7 !important;
    background-color: transparent !important;
    border: 1px solid transparent;
    cursor: not-allowed;
  }

  &.active {
    a {
      background-color: @base-color !important;
      color: #fff !important;
    }
  }

  &:first-child {
    a {
      border: none !important;
    }
  }
}

.p-l-5 {
  padding-left: 5px !important;
}

.m-l-20 {
  margin-left: 20px;
}

.m-r-20 {
  margin-right: 20px;
}

.m-b-20 {
  margin-bottom: 20px;
}

.solutions p {
  line-height: 30px !important;
}

.lh-30 {
  line-height: 30px !important;
}

.list-icons-alt {

  p {
    margin-bottom: 0;
    display: -webkit-box;
    padding-left: 15px;
  }
  i {
    float: left;
    color: @base-color;
    font-size: 18px;
    margin-right: 5px;
  }
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer !important;
}

.careers {
  line-height: 25px;
}

/* =Header
-------------------------------------------------------------- */
header {

  .container {
    position: relative;
  }

  &.animated {
    #social_media_wrapper, #sign {
      display: none;
    }
    .navbar {
      margin: 10px 0px 15px 0px;
    }

    .logo img {
      max-height: none !important;
    }
  }
}

.top_bar {
  background-color: @brand-primary;
  border-bottom: solid 2px #ffffff;
  color: #ffffff;
  padding: 0px 0;
  height: auto;
  overflow: hidden;

  .tob_bar_left_col {
  }
  .tob_bar_right_col {
    text-align: right;
    p {
      font-size: 12px;
      padding: 0;
      margin: 0;
      line-height: 38px;
    }
  }
  ul.top_menu {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      float: left;
      border-right: solid 0px #cfcfcf;
      &:last-child {
        border-right: solid 0px #ffffff;
      }
      a {
        display: inline-block;
        padding: 10px 10px;
        font-size: 12px;
        &:link, &:visited {
          color: #ffffff;
          border-bottom: solid 1px @brand-primary;
        }
        &:hover, &:active {
          border-bottom: solid 1px yellow;
        }
      }
    }
  }
}

.navbar {
  margin: 50px 0px 15px 0px;
  background-color: transparent !important;
  border: none !important;
  background-color: transparent;
  background-image: none;
  border: 0px;
  box-shadow: none;

  padding: 0px;
}

.lt-ie9 .navbar {
  .nav {
    li a {
      margin: 0px;
      padding: 10px;
      font-size: 14px;
    }
    .dropdown-menu > li > a {
      padding: 3px 20px;
    }
  }
}

.navbar .btn-navbar {
  margin-top: 22px;
  margin-right: 15px;
  margin-left: 0;
  span.nb_left {
    padding: 5px;
  }
  span.nb_right {
    font-weight: bold;
    font-size: 18px;
    color: @gray;
    text-shadow: 1px 1px 0 #FFFFFF;
    padding: 0 4px 4px 4px;
  }
}

.in ul.nav {
  width: 100%;
  margin: 0;
  background: #ffffff;
  li {
    text-align: center;
  }
  li a {
    margin: 0 0 6px 0px;

  }
  li a:hover, li a:active {
    color: @brand-primary;
    text-shadow: 0px 0px 0 transparent;
    border-bottom: solid 0px @link-color;
  }
  li.active a, li.active a:hover {
    color: #fff;
    text-shadow: 0px 0px 0 transparent;
    border-bottom: solid 0px @link-color;
  }
}

@border-radius-base: 4px;
@border-radius-large: 6px;
@border-radius-small: 3px;
// Single side border-radius

.border-top-radius(@radius) {
  border-top-right-radius: @radius;
  border-top-left-radius: @radius;
}

.border-right-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-top-right-radius: @radius;
}

.border-bottom-radius(@radius) {
  border-bottom-right-radius: @radius;
  border-bottom-left-radius: @radius;
}

.border-left-radius(@radius) {
  border-bottom-left-radius: @radius;
  border-top-left-radius: @radius;
}

#social_media_wrapper {
  background: @brand-primary;
  .border-bottom-radius(@border-radius-base);
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 15px;
  z-index: 101;
  overflow: hidden;
  padding: 10px;
  a {
    text-align: center;
    display: block;
    float: left;
    margin: 0 3px;
    background-color: #ffffff;
    border-radius: 50%;
    font-size: 18px;
    width: 30px;
    height: 30px;
    padding: 6px;

  }
  a i {
    vertical-align: top !important;
  }
  a:link, a:visited {
  }
  a:hover, a:active {
    text-decoration: none;
    color: #fff;
    background: @brand-primary;
  }
}

#sign {
  position: absolute;
  top: 7px;
  //-- Change "right" if you put more than 3 social icons ----
  right: 200px;
  font-size: 12px;
  z-index: 101;
  height: 50px;
  line-height: 40px;
  padding-right: 10px;
  i {
    //margin-right: 0px;
    font-size: 18px;
    //width: 28px;
    //height: 28px;
    padding: 0 6px;
    display: inline-block;
    //text-align: center;
    //position: relative;
    //left: 0px;
    //top: 2px;
    color: #777777;
  }
}

.m-t-20 {
  margin-top: 20px;
}

.m-r-10 {
  margin-right: 10px;
}

.team-member .body p {
  line-height: 22px;
}

.blogpost {
  margin: 0 0 20px !important;
  footer {
    border: none;
  }
}

.img-responsive {
  width: 100%;
}

.social-inline-block {
  display: inline-block;
  margin: 0 6px !important;
}

.solution-summary  {
  margin-bottom: 15px;
  position: relative;

  h2, .body h3  {
    .text-primary;
    text-transform: none;
    font-weight: 400;
  }
  .body {
    min-height: 280px!important;
    position: relative;
    overflow: hidden;
    .contracts-container {
      height: 85%;
      max-height: 220px!important;
      overflow-y: scroll;
      table {
        height: 100%!important;
        margin-bottom: 0!important;
      }
    }
  }
  .col-md-6 {
    position: relative;
    height: 100%;
    .overlay-container {
      display: block;
      margin: 0 auto!important;
      min-height: 280px;
      width: 100%;
      img {
        background-repeat: no-repeat;
        background-position: center center;
      }
    }



  }
}