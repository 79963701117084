.program-logo {
  padding: 10px!important;
  float: left;
  img {
    height: 40px;
    text-align: center;
    margin: 0 auto;
    display: block;
  }
}

.link-outline-dark {
  background: transparent;
  border: 1px solid #0b0b0b;
  border-radius: 0;
  color: #0b0b0b;
  padding: 7px 30px;
  display: inline-block;
  margin-right: 35px;
  text-decoration: none;
  float: left;
  text-transform: uppercase;
  font-family: 'Open Sans', sans-serif;
  &:hover {
    background: #0b0b0b;
    text-decoration: none;
    color: #fff;
  }
}

.link-dark {
  background: transparent;
  border-top: none;
  border-right: none;
  text-decoration-line: none!important;
  border-left: none;
  border-bottom: 1px solid #0b0b0b;
  border-radius: 0;
  color: #0b0b0b;
  display: inline-block;
  padding: 7px 0px 2px;
  float: left;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;

  &:hover {
    background: transparent;
    border-top: none;
    border-right: none;
    text-decoration-line: none!important;
    border-left: none;
    border-bottom: 1px solid #0b0b0b;
    border-radius: 0;
    color: #0b0b0b;
    display: inline-block;
    padding: 7px 0px 2px;
    float: left;
    text-transform: uppercase;
  }
}
.program-title {
  text-transform: none!important;
  color: @primary-color!important;
  font-weight: 500!important;
}

.program-bg {
  min-height: 550px;
  padding-top: 25px;
  background-size: cover;
  background-image: url('/site/images/page-about-2.jpg');
  background-repeat: no-repeat;
}

.banner-logo {
  height: 75px;
  margin: 5px 0;
}

.program-banner-info {
  h4 {
    margin: 25px 0 !important;
    font-size: 18px;
  }
  a {
    text-decoration: none !important;
  }
}

.program-login-form {
  // Admin Template

  h3 {
    margin-top: 35px;
  }
  a {
    font-weight: 400 !important;
    margin-top: 5px;
    &:hover, &:active, &:focus {
      color: #fff !important;
    }
  }

  .form-group {
    .input-group-addon {
      background-color: @primary-color;
      border-radius: 0 !important;
      border-color: @primary-color;
      i {
        color: #ffffff;
      }
    }

    input {
      opacity: 1 !important;
      color: #000 !important;
      border-radius: 0 !important;
      font-size: 16px;
      height: 45px;
      border: 0.1em solid @primary-color !important;
      border-left: none !important;
    }
  }

  a {
    text-decoration: none !important;
    font-weight: 300 !important;
    &:hover {
      text-decoration: underline !important;

    }
  }
  .login-container {
    overflow-y: scroll;
  }

  /* Login */
  .login-header > .brand > img {
    display: inline;
  }
  .login-buttons {
    button {
      width: 150px;
      padding-top: 5px;
      padding-bottom: 5px;
      background-color: @primary-color !important;
      border-color: darken(@primary-color, 20%) !important;
      &:hover, &:focus, &:active, &.active {
        background-color: darken(@primary-color, 20%) !important;
      }
    }
  }
}


.navbar-default .navbar-nav > li > a {
  padding: 20px 5px;
  font-size: 16px;
}

.header.animated .navbar {
  margin-bottom: 10px;
}


.footer {
  padding: 10px 0;
}

.program-info-container {

  margin-bottom: 35px;

  .contracts-container {
    height: 225px !important;
    justify-content: left;
    text-align: left !important;
    overflow-y: scroll;
  }
}
@media (min-width: 768px){

  .navbar-nav {
    float: right;
  }

}
@media only screen and (max-width: 1089px) {

  .program-logo, .navbar-nav {
    float: none;
    text-align: center;
    display: block;
    margin: 0 auto;
  }
  .navbar-nav {
    padding-left: 0;
    display: table;
  }

  .program-banner-info {
    h4 {
      margin: 15px 0 !important;
      font-size: 16px;
    }
  }

  .program-login-form {
    // Admin Template

    h3 {
      margin-top: 25px;
    }

    .form-group {
      input {
        width: auto !important;
        font-size: 16px;
        height: 45px;
      }
    }

    .login-buttons {
      button {
        width: 120px;
      }
    }

  }

}

@media only screen and (max-width: 991px) {
  .program-banner-info {
    display: none;
  }
  .program-login-form {
    // Admin Template
    text-align: center;
    h3 {
      margin-top: 25px;
    }

    .form-group {
      input {
        width: 100%!important;
        font-size: 16px;
        height: 45px;
      }
    }

    .login-buttons {
      button {
        width: 350px;
      }
    }

  }
}

@media (max-width: 767px) {
  .program-logo {
    position: absolute;
    left:5px;
    top: 5px;
  }
}

@media (max-width: 480px) {

}

@media (max-width: 380px) {

}

@media (max-width: 340px) {

}


